import { createTheme } from "@mui/material";
import { createBreakpoints } from "@mui/system";

const breakpoints = createBreakpoints({});

const theme = createTheme({
    typography: {
        fontFamily: [
            'Poppins',
            'sans-serif'
        ].join(','),
        h1: {
            fontWeight: 600,
            fontSize: "4rem",
            [breakpoints.down("md")]: {
                fontSize: 40
            },
        },
        h2: {
            fontSize: "2.4rem",
            fontWeight: 600,
            paddingTop: "0.5rem",
            paddingBottom: "2rem",
            [breakpoints.down("md")]: {
                fontSize: 26
            },
        },
        h3: {
            fontSize: "1.1rem",
            fontWeight: 600,
            color: "#1F7A8E",
            textTransform: "uppercase",
        },
        h5: {
            fontSize: "1rem",
            fontWeight: 600,
            textTransform: "capitalize",
            paddingTop: "1.3rem",
            paddingBottom: "1.5rem"
        },
        h6: {
            fontSize: "1rem",
            fontWeight: 400,
            textTransform: "capitalize",
            paddingTop: "1.3rem",
            paddingBottom: "1.5rem"
        }
    },
    palette: {
        primary: {
            main: "#1F7A8E"
        }
    }
});

export default theme;